import styled from "styled-components"
import { down } from "styled-breakpoints"

export const Box3 = styled.div`
  width: ${props => (props.width ? props.width : "400px")};
  ${down("lg")} {
    width: 300px;
  }
  /* ${down('md')} {
    width: 100%;
  } */
  display: flex;
  overflow: hidden;
  flex-direction: column;
  /* background: #ffffff; */
  box-shadow: ${props =>
    props.boxShadow ? props.boxShadow : "0px 14px 24px rgba(0, 47, 108, 0.07)"};
  position: relative;
  border: 4px solid transparent;
  border-radius: 4px;
  border-bottom: ${props =>
    props.borderBottom ? props.borderBottom : `4px solid ${props.color}`};

  &:hover {
    border: 4px solid ${props => props.color};

    .card-image-wrapper {
      border-radius: 0;
    }
  }
`
