import React from "react"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import LanguageIcon from "../icons/LanguageIcon"
import { Typography } from "../styled/typography"
import { down } from "styled-breakpoints"

export default function Topbar() {
  const { originalPath, i18n } = useI18next()
  const { t } = useTranslation()

  return (
    <div className="topbar">
      <div
        css={`
          display: flex;
          max-width: 1440px;
          margin: 0 auto;
          align-items: center;
          justify-content: space-between;
          padding: 0 28px 0 32px;
        `}
      >
        <div
          css={`
            display: flex;
            align-items: center;

            ${down("lg")} {
              margin-right: 16px;
            }
          `}
        >
          <LanguageIcon />
          <Link
            to={originalPath}
            language={i18n.resolvedLanguage === "en-us" ? "el-gr" : "en-us"}
          >
            <Typography
              variant="detail_regular"
              color="#fff"
              css={`
                margin-left: 1ch;
              `}
            >
              {i18n.resolvedLanguage === "en-us" ? "Ελληνικά" : "English"}
            </Typography>
          </Link>
        </div>
        <div>
          <TagLink
            href="https://imegsevee.gr"
            color="#842C41"
            name="IME ΓΣΕΒΕΕ"
          />
          <TagLink
            href="https://www.kekgsevee.gr"
            color="#005C5D"
            name="KEK ΓΣΕΒΕΕ"
          />
          <TagLink
            href="https://historygsevee.imegsevee.gr"
            color="#AF8373"
            name="100 XPONIA ΓΣΕΒΕΕ"
          />
          {/* <TagLink
            href="https://previous.gsevee.gr/"
            color="#004399"
            name={t("common:oldWebsite")}
          /> */}
        </div>
      </div>
    </div>
  )
}

function TagLink({ href, color, name }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      css={`
        display: inline-block;
        padding: 1px 4px;
        margin: 4px;
        border-radius: 3px;
        color: white;
        background: ${color};
        text-transform: uppercase;

        &:hover {
          color: #002f6c;
          background: #fff;
        }
      `}
    >
      {name}
    </a>
  )
}
