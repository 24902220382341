import styled from "styled-components"

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid rgba(0, 47, 108, 0.2);
`

export default FilterWrapper
