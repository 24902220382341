const greekUtils = require("greek-utils")

exports.linkResolver = doc => {
  // URL for a category type
  if (doc.type === "category") {
    return `/category/${greekUtils.toGreeklish(doc.uid)}`
  }

  // URL for a resource type
  if (doc.type === "resource") {
    return `/resource/${greekUtils.toGreeklish(doc.uid)}`
  }

  if (doc.type === "generic_page") {
    return `/pages/${greekUtils.toGreeklish(doc.uid)}`
  }

  // Backup for all other types
  return "/"
}
