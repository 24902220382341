import * as React from "react"

function TwitterIcon(props) {
  return (
    <svg
      width={98}
      height={90}
      viewBox="0 0 98 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={98} height={90} rx={8} fill="#1DA1F2" />
      <path
        d="M49 24c-11.597 0-21 9.403-21 21s9.403 21 21 21 21-9.403 21-21-9.403-21-21-21zm10.092 15.83c.014.22.014.45.014.675 0 6.88-5.24 14.807-14.817 14.807-2.953 0-5.69-.857-7.997-2.334.422.047.825.066 1.256.066 2.438 0 4.679-.825 6.465-2.222a5.207 5.207 0 01-4.866-3.61c.801.118 1.523.118 2.348-.093a5.204 5.204 0 01-4.167-5.11v-.065c.69.389 1.5.628 2.349.66a5.21 5.21 0 01-2.32-4.33c0-.97.252-1.857.707-2.626a14.778 14.778 0 0010.734 5.443c-.736-3.54 1.908-6.404 5.086-6.404a5.2 5.2 0 013.802 1.641c1.176-.22 2.301-.66 3.305-1.251a5.182 5.182 0 01-2.288 2.864 10.297 10.297 0 003-.811 10.91 10.91 0 01-2.61 2.7z"
        fill="#fff"
      />
    </svg>
  )
}

export default TwitterIcon
