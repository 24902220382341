import React from "react"
import { Link } from "gatsby"

export function AboutSubmenu() {
  return (
    <ul>
      <ItemLink to="/about">Σχετικά με εμάς</ItemLink>
      <li>
        <hr
          css={`
            border-color: 1px solid rgba(255, 255, 255, 0.4);
            margin: 8px 0;
          `}
        />
      </li>
      <ItemLink
        css={`
          &&& {
            color: rgba(255, 255, 255, 0.4);
          }
        `}
      >
        Όργανα Διοίκησης
      </ItemLink>
      <ItemLink to="/pages/genikisineleusi">Γενική Συνέλευση</ItemLink>
      <ItemLink to="/pages/dioikhtiko-symboulio">Διοικητικό Συμβούλιο</ItemLink>
      <ItemLink to="/pages/proedreio">Προεδρείο</ItemLink>
      <ItemLink to="/pages/elegktikh-epitroph">Ελεγκτική Επιτροπή</ItemLink>
    </ul>
  )
}

export function EnInformationSubmenu() {
  return (
    <ul>
      <ItemLink to="/pages/general-assembly">General Assembly</ItemLink>
      <ItemLink to="/pages/administrative-board">Administrative Board</ItemLink>
      <ItemLink to="/pages/presidium">Presidium</ItemLink>
      <ItemLink to="/pages/audit-committee">Audit Committee</ItemLink>
    </ul>
  )
}

function ItemLink({ children, className, to }) {
  return (
    <li
      css={`
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        margin: 8px 0;
      `}
      className={className}
    >
      {to ? (
        <Link
          to={to}
          css={`
            color: #fff;

            :hover {
              color: #fff;
            }
          `}
        >
          {children}
        </Link>
      ) : (
        children
      )}
    </li>
  )
}
