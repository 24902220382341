import React from "react"
import styled from "styled-components"
import MembersEl from "../../../images/svgs/about-incorporates-el.inline.svg"
import { down } from "styled-breakpoints"
import { Link } from "gatsby"

const DiagramInfo = styled.p`
  font-family: CeraGR-Regular;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 32px 58px;
  ${down("lg")} {
    //styleName: Subtitles/Small;
    font-family: CeraGR-Regular;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
`
const RightBoardTitle = styled.div`
  font-family: CeraGR-Bold;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #002f6c;

  border-radius: nullpx;
  margin-bottom: 34px;
`

const RightBoardNote = styled.p`
  font-family: CeraGR-Medium;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
  color: #002f6c;
  width: 100%;
`
const FlatButton = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ca998e;
  align-items: center;
`
const FooterLink = styled(Link)`
  span {
    color: "#002f6c";
  }
  &:hover {
    background: #001838;
    span {
      color: #fff;
    }
  }
`

const Footer3 = props => {
  return (
    <React.Fragment>
      <div
        className="d-flex flex-wrap m-0"
        css={`
          justify-content: space-between;
        `}
      >
        <MembersEl />
        <div className="col-12 col-lg-4 left-panel">
          <div className="m-0 ms-lg-4">
            <RightBoardTitle>Εγγραφή μέλους</RightBoardTitle>
            <p style={{ margin: "8px 0" }}>Για φορείς:</p>
            <RightBoardNote>Οδηγίες εγγραφής και έντυπα</RightBoardNote>
            <FlatButton className="w-100">
              <FooterLink
                state={{ backTo: "/members" }}
                to="/pages/odhgies-eggrafhs-kai-entypa"
                className="w-100 py-3 py-md-2 px-3 px-md-2 d-flex justify-content-between t-underline"
              >
                <span>Διαβάστε περισσότερα</span>
                <span>&#10140;</span>
              </FooterLink>
            </FlatButton>
          </div>
          <div className="m-0 ms-lg-4">
            <RightBoardTitle
              css={`
                margin-top: 42px;
              `}
            >
              Χρήσιμες Πληροφορίες
            </RightBoardTitle>
            <p style={{ margin: "8px 0" }}>Για μέλη:</p>
            <FlatButton className="w-100">
              <FooterLink
                state={{ backTo: "/members" }}
                to="/pages/xrhsimes-plhrofories-gia-melh"
                className="w-100 py-3 py-md-2 px-3 px-md-2 d-flex justify-content-between t-underline"
              >
                <span>Διαβάστε περισσότερα</span>
                <span>&#10140;</span>
              </FooterLink>
            </FlatButton>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer3
