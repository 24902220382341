import React from "react"
const DownloadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="12" cy="12" r="12" fill="#002F6C" />
      <path
        d="M11.9747 4.2708C11.9627 4.27298 11.9506 4.27626 11.9397 4.27955C11.8095 4.30908 11.7177 4.42611 11.7209 4.55955V13.117L9.96219 11.3583C9.89438 11.2883 9.79484 11.2588 9.69969 11.2795C9.59469 11.2981 9.51047 11.3747 9.48094 11.4764C9.45141 11.5792 9.48203 11.6886 9.55969 11.7608L11.7997 14.0008L12.0009 14.1933L12.2022 14.0008L14.4422 11.7608C14.5538 11.6492 14.5538 11.4699 14.4422 11.3583C14.3306 11.2467 14.1513 11.2467 14.0397 11.3583L12.2809 13.117V4.55955C12.2842 4.47861 12.2514 4.40095 12.1934 4.34626C12.1344 4.29048 12.0545 4.26314 11.9747 4.2708ZM6.96094 8.47955V17.9995H17.0409V8.47955H14.2409C14.1403 8.47845 14.0463 8.53095 13.9948 8.61845C13.9445 8.70595 13.9445 8.81314 13.9948 8.90064C14.0463 8.98814 14.1403 9.04064 14.2409 9.03955H16.4809V17.4395H7.52094V9.03955H9.76094C9.86156 9.04064 9.95563 8.98814 10.007 8.90064C10.0573 8.81314 10.0573 8.70595 10.007 8.61845C9.95563 8.53095 9.86156 8.47845 9.76094 8.47955H6.96094Z"
        fill="#002F6C"
      />
      <path
        d="M11.9747 4.2708C11.9627 4.27298 11.9506 4.27626 11.9397 4.27955C11.8095 4.30908 11.7177 4.42611 11.7209 4.55955V13.117L9.96219 11.3583C9.89438 11.2883 9.79484 11.2588 9.69969 11.2795C9.59469 11.2981 9.51047 11.3747 9.48094 11.4764C9.45141 11.5792 9.48203 11.6886 9.55969 11.7608L11.7997 14.0008L12.0009 14.1933L12.2022 14.0008L14.4422 11.7608C14.5538 11.6492 14.5538 11.4699 14.4422 11.3583C14.3306 11.2467 14.1513 11.2467 14.0397 11.3583L12.2809 13.117V4.55955C12.2842 4.47861 12.2514 4.40095 12.1934 4.34626C12.1344 4.29048 12.0545 4.26314 11.9747 4.2708ZM6.96094 8.47955V17.9995H17.0409V8.47955H14.2409C14.1403 8.47845 14.0463 8.53095 13.9948 8.61845C13.9445 8.70595 13.9445 8.81314 13.9948 8.90064C14.0463 8.98814 14.1403 9.04064 14.2409 9.03955H16.4809V17.4395H7.52094V9.03955H9.76094C9.86156 9.04064 9.95563 8.98814 10.007 8.90064C10.0573 8.81314 10.0573 8.70595 10.007 8.61845C9.95563 8.53095 9.86156 8.47845 9.76094 8.47955H6.96094Z"
        stroke="#002F6C"
      />
    </svg>
  )
}
export default DownloadIcon
