import * as React from "react"

function LinkedInIcon(props) {
  return (
    <svg
      width={98}
      height={90}
      viewBox="0 0 98 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={98} height={90} rx={8} fill="#0E76A8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.667 29.73a3.063 3.063 0 013.063-3.063h30.537a3.063 3.063 0 013.067 3.063v30.537a3.064 3.064 0 01-3.065 3.066H33.73a3.064 3.064 0 01-3.063-3.065V29.73zM45.18 40.647h4.965v2.493c.717-1.433 2.55-2.723 5.305-2.723 5.282 0 6.534 2.855 6.534 8.093v9.703h-5.345v-8.51c0-2.983-.717-4.666-2.537-4.666-2.525 0-3.575 1.815-3.575 4.666v8.51H45.18V40.647zm-9.166 17.338h5.346V40.417h-5.346V57.985zm6.111-23.298a3.438 3.438 0 11-6.875.151 3.438 3.438 0 016.875-.151z"
        fill="#fff"
      />
    </svg>
  )
}

export default LinkedInIcon
