import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { down } from "styled-breakpoints"
import styled from "styled-components"
import ChevronRightIcon from "../images/svgs/chevron-right.inline.svg"
import Attachments from "../components/common/Attachments"
import DownloadIcon from "../components/common/DownloadIcon"
import FilterItem, {
  FilterItem2,
  FilterItem4,
} from "../components/common/FilterItem"
import ListBox, { ListItem } from "../components/common/ListBox"
import { PopupContainer, PopupMenuItem } from "../components/common/PopupMenu"
import Section from "../components/common/Section"
import SocialModal from "../components/common/SocialModal"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { CustomRichText } from "../components/styled"
import formatDate from "../utils/formatDate"
import { Attach, Linked, Printer } from "../utils/imgImport"
import {
  filterCategoryBySubtag,
  getResourceSubtags,
  getSubtagsByCategory,
} from "../utils/staticData"

const BreadCrumb2 = styled.h5`
  display: flex;
  margin-bottom: 58px;
  margin-top: 80px;
  cursor: pointer;
  ${down("lg")} {
    margin-bottom: 25px;
    margin-top: 57px;
  }
`
const BreadCrumb2Item = styled.span`
  margin: 0 4px;
  font-family: "CeraGR-Medium";
  font-size: 20px;
  line-height: 30px;
  color: ${props => props.theme.colors.blue};
`
const TileWrapper = styled.div`
  ${down("lg")} {
    & .title-br {
      display: none;
    }
    & h4 {
      //styleName: H5/ Bold;
      font-family: CeraGR-Bold;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.02em;
      text-align: left;
    }
    & h5 {
      //styleName: Details/Regular;
      font-family: CeraGR-Regular;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`

const Period = styled.p`
  font-family: CeraGR-Regular;
  font-size: 16px;
  line-height: 22px;
`

const Resource = ({ data, location }) => {
  const resourceDetail = data.prismicResource.data
  const subtagData = data.allPrismicSubtag.nodes
  const categoryData = data.allPrismicCategory.nodes

  const {
    title,
    subtitle,
    rich_text,
    post_date,
    group_of_subtags,
    event_starting_time,
    event_ending_time,
    resource_photo,
    embeds,
  } = resourceDetail

  const resourceCategories = filterCategoryBySubtag(
    categoryData,
    getResourceSubtags(subtagData, group_of_subtags)
  )

  const [popupShow, setPopupShow] = useState("")
  const handleShowPrint = () => {}
  const onAfterOpen = () => {}

  const attachments = embeds
    .filter(embed => embed.url?.url)
    .filter(embed => !embed.url.url.includes("tableau"))
  const tableauEmbeds = embeds
    .filter(embed => embed.url?.url)
    .filter(embed => embed.url.url.includes("tableau"))

  return (
    <Layout location={location} type="primary">
      <Seo title="Resources Page" />

      <Section className="activity-section">
        <TileWrapper className="container">
          <BreadCrumb2>
            <BreadCrumb2Item>
              <ChevronRightIcon
                css={`
                  transform: scaleX(-1);
                `}
              />
            </BreadCrumb2Item>
            <Link to="/resources">
              <BreadCrumb2Item>Πίσω</BreadCrumb2Item>
            </Link>
          </BreadCrumb2>
          <div className="row my-4">
            <span>{formatDate(post_date)}</span>
          </div>
          <h4 className="mb-5">{title}</h4>
          <h5 className="mb-5">{subtitle}</h5>
          {event_ending_time !== null && event_starting_time !== null && (
            <Period>{`${formatDate(event_starting_time)} - ${formatDate(
              event_ending_time
            )}`}</Period>
          )}
          {/* {author && <Author>{author}</Author>} */}
        </TileWrapper>
        <div className="container">
          <ListBox>
            <PopupContainer
              show={popupShow === "attach"}
              popupMenu={attachments
                .map(x => x.url.raw)
                .map(file => (
                  <PopupMenuItem
                    key={file.url}
                    to={file.url}
                    endItem={<DownloadIcon />}
                  >
                    {file.name}
                  </PopupMenuItem>
                ))}
            >
              <div className="desktop-activities">
                <ListItem
                  startItem={
                    attachments.length ? (
                      <Attachments
                        onClick={() =>
                          setPopupShow(popupShow === "attach" ? "" : "attach")
                        }
                      />
                    ) : null
                  }
                  endItem={
                    <React.Fragment>
                      <FilterItem2
                        icon={Printer}
                        label="Εκτύπωση"
                        mr="25px"
                        onClick={() => handleShowPrint()}
                      />
                      <FilterItem2
                        icon={Linked}
                        label="Κοινοποίηση"
                        onClick={() => setPopupShow("modal")}
                      />
                    </React.Fragment>
                  }
                  endAlign={{ justifyContent: "flex-end" }}
                />
              </div>
              <div className="mobile-activities">
                <div className="d-flex justify-content-between">
                  <PopupContainer
                    show={popupShow === "attach2"}
                    popupMenu={attachments
                      .map(x => x.url.raw)
                      .map(file => (
                        <PopupMenuItem
                          key={file.url}
                          to={file.url}
                          endItem={<DownloadIcon />}
                        >
                          {file.name}
                        </PopupMenuItem>
                      ))}
                  >
                    <FilterItem4
                      icon={Attach}
                      label="Συνημμένα"
                      mr="25px"
                      onClick={() =>
                        setPopupShow(popupShow === "attach2" ? "" : "attach2")
                      }
                    />
                  </PopupContainer>

                  <FilterItem4
                    icon={Printer}
                    label="Εκτύπωση"
                    mr="25px"
                    onClick={() => handleShowPrint()}
                  />
                  <FilterItem4
                    icon={Linked}
                    label="Κοινοποίηση"
                    onClick={() => setPopupShow("modal")}
                  />
                </div>
              </div>
            </PopupContainer>

            {resourceCategories.map((item, idx) => (
              <ListItem
                key={idx}
                type={2}
                startItem={
                  <FilterItem2
                    label={item.data.title}
                    icon={item.data.icon.url}
                  />
                }
                endItem={getSubtagsByCategory(
                  getResourceSubtags(subtagData, group_of_subtags),
                  item.prismicId
                )
                  .slice(0, 3)
                  .map((item, i) => (
                    <FilterItem types={2} key={`subtag${i}`}>
                      {item.tag_name}
                    </FilterItem>
                  ))}
                endAlign={{ justifyContent: "flex-start" }}
              />
            ))}
          </ListBox>
        </div>

        <div className="container">
          <GatsbyImage
            css={`
              border-radius: 4px;
              margin-bottom: 48px;
            `}
            image={getImage(resource_photo)}
            alt="resource"
            className="w-100"
            width={"100%"}
          />
          <CustomRichText
            dangerouslySetInnerHTML={{ __html: rich_text.html }}
          />
        </div>
        <div className="container">
          {tableauEmbeds.map(embed => (
            <iframe
              css={`
                margin: 48px auto;
              `}
              key={embed.url.url}
              title="tableu"
              width="100%"
              height="600"
              src={embed.url.url}
            ></iframe>
          ))}
        </div>
      </Section>
      <SocialModal
        isOpen={popupShow === "modal"}
        onAfterOpen={onAfterOpen}
        onRequestClose={() => setPopupShow("")}
      />
    </Layout>
  )
}

export default Resource

export const query = graphql`
  query ResourceQuery($id: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicResource(id: { eq: $id }) {
      data {
        title
        subtitle
        post_date
        resource_photo {
          gatsbyImageData
          url
        }
        event_ending_time
        event_starting_time
        group_of_subtags {
          subtag {
            id
          }
        }
        rich_text {
          html
        }
        embeds {
          url {
            raw
            url
          }
        }
      }
    }
    allPrismicSubtag {
      nodes {
        data {
          tag_name
          category {
            id
          }
        }
        prismicId
      }
    }
    allPrismicCategory(filter: { lang: { eq: "el-gr" } }) {
      nodes {
        data {
          title
          color
          icon {
            url
          }
        }
        prismicId
      }
    }
  }
`
