import * as React from "react"

export default function LanguageIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 8c.325 0 .617.198.738.5l3.2 8a.365.365 0 01-.338.5.795.795 0 01-.739-.5l-.6-1.5h-4.523l-.6 1.5a.795.795 0 01-.738.5.364.364 0 01-.338-.5l3.199-8c.12-.302.413-.5.739-.5zm0 1.346L12.138 14h3.723L14 9.346zM6.762 3.413l.366 1.586H10.5a.5.5 0 010 1h-.993v.027c-.07 1.57-.886 3.182-2.235 4.502a9.489 9.489 0 002.89 1.474.5.5 0 01-.3.955A10.49 10.49 0 016.505 11.2a10.462 10.462 0 01-3.356 1.757.5.5 0 11-.298-.955 9.54 9.54 0 002.89-1.473C4.383 9.201 3.567 7.58 3.504 6H2.5a.5.5 0 010-1h3.603l-.315-1.361a.5.5 0 01.289-.572l.086-.028a.5.5 0 01.6.375zM6.505 9.88l.01-.008C7.717 8.718 8.44 7.324 8.506 6H4.505v.012c.07 1.323.795 2.716 2 3.868z"
        fill="#fff"
      />
    </svg>
  )
}
