import * as React from "react"

function FacebookIcon(props) {
  return (
    <svg
      width={98}
      height={90}
      viewBox="0 0 98 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={98} height={90} rx={8} fill="#4267B2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 45.112C29 55.055 36.222 63.323 45.667 65V50.555h-5V45h5v-4.445c0-5 3.221-7.777 7.778-7.777 1.443 0 3 .222 4.443.444v5.111h-2.555c-2.445 0-3 1.222-3 2.779V45h5.334l-.889 5.555h-4.445V65C61.778 63.323 69 55.057 69 45.112 69 34.05 60 25 49 25s-20 9.05-20 20.112z"
        fill="#fff"
      />
    </svg>
  )
}

export default FacebookIcon
