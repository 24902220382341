import {
  Block,
  CardImg1,
  CardImg2,
  CardImg3,
  CardImg4,
  CardImg5,
  dash_Contact,
  dash_Economy,
  dash_Home,
  dash_Institutional,
  Economy,
  Institutional,
  ReportImg1,
  ReportImg2,
  Resource1,
  Resource2,
  Resource3,
  Social,
  Training,
  Work,
} from "./imgImport"

export const blocks = [
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
  Block,
]

export const resources = [
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κατάρτιση - Δεξιότητες",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
]

export const dashboard_report_data = [
  {
    img: ReportImg1,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
  {
    img: ReportImg2,
    content_type: "Νέα",
    title: "Βραβείο ΕΒΓΕ για την ετήσια",
    category: "Θεσμική παρουσία",
    date: "22 Ιου. 2021",
  },
]

export const category_activities = [
  {
    img: CardImg4,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν σε κίνδυνο την ανάκαμψη των ΜΜΕ",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
  {
    img: CardImg3,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν σε κίνδυνο την ανάκαμψη των ΜΜΕ",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
  {
    img: CardImg5,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν σε κίνδυνο την ανάκαμψη των ΜΜΕ",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
  {
    img: CardImg4,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν σε κίνδυνο την ανάκαμψη των ΜΜΕ",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
  {
    img: CardImg3,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν σε κίνδυνο την ανάκαμψη των ΜΜΕ",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
  {
    img: CardImg5,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν σε κίνδυνο την ανάκαμψη των ΜΜΕ",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
  {
    img: CardImg4,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν σε κίνδυνο την ανάκαμψη των ΜΜΕ",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
  {
    img: CardImg3,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν σε κίνδυνο την ανάκαμψη των ΜΜΕ",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
  {
    img: CardImg5,
    content_type: "Δελτίο Τύπου",
    title: "Οι τιμές ενέργειας και υλών θέτουν σε κίνδυνο την ανάκαμψη των ΜΜΕ",
    category: "Διεθνείς σχέσεις",
    date: "14 Απρ. 2021",
  },
]
export const activites = [
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κατάρτιση - Δεξιότητες",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κατάρτιση - Δεξιότητες",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κατάρτιση - Δεξιότητες",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource1,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Θεσμική παρουσία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource2,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Εργασία",
    date: "14 Απρ. 2021",
  },
  {
    img: Resource3,
    content_type: "Βίντεο",
    title: "Ιδιωτικό χρέος & Πτωχευτικός",
    category: "Κοινωνική πολιτική",
    date: "14 Απρ. 2021",
  },
]

export const findCategoryIconByLabel = label => {
  const data = category_menus.filter(item => item.subtitle === label)
  if (data.length === 0) return undefined
  return data[0].icon
}

export const category_filters = [
  { label: "Όλα" },
  { color: "#FF8275", label: "Θεσμική παρουσία", value: "Θεσμική παρουσία" },
  {
    color: "#63C6DC",
    label: "Οικονομία - Επιχειρηματικότητα",
    value: "Οικονομία - Επιχειρηματικότητα",
  },
  { color: "#AF8373", label: "Εργασία", value: "Εργασία" },
  {
    color: "#3BBBA3",
    label: "Κοινωνική πολιτική",
    value: "Κοινωνική πολιτική",
  },
  {
    color: "#CFA867",
    label: "Κατάρτιση & δεξιότητες",
    value: "Κατάρτιση & δεξιότητες",
  },
]

export function getCategoryFilters(categoryData) {
  return categoryData.map(category => ({
    color: category.data.color,
    label: category.data.title,
    value: category.uid,
  }))
}

export const findCategoryColorByLabel = (category_filters, label) => {
  const data = category_filters.filter(item => item.label === label)
  if (data.length === 0) return undefined
  return data[0].color
}

export const getResourceSubtags = (allSubtags, groupSubtags) => {
  const fn = item => {
    for (let i = 0; i < allSubtags.length; i++) {
      if (allSubtags[i].prismicId === item.subtag.id) {
        return allSubtags[i].data
      }
    }
  }
  return groupSubtags.map(fn)
}
export const getSubtagsByCategory = (subtags, categoryId) => {
  return subtags.filter(item => item?.category.id === categoryId)
}
export const getCategoryName = (categories, subtags) => {
  const fn = item => {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].prismicId === item?.category.id) {
        return categories[i].data
      }
    }
  }
  return subtags.map(fn)
}
export const filterCategoryBySubtag = (categories, subtags) => {
  const fn = item => {
    for (let i = 0; i < subtags.length; i++) {
      if (subtags[i]?.category?.id === item.prismicId) {
        return categories[i].data
      }
    }
  }
  return categories.filter(fn)
}

export const findCategoryItemsBy = label => {
  const data = category_menus.filter(item => item.subtitle === label)
  if (data.length === 0) return []
  return data[0].items
}

export const findByKey = (arrays, keyField, keyValue) => {
  const data = arrays.filter((element, i) => {
    return keyValue === element[keyField]
  })
  if (data.length === 0) return undefined
  return data[0]
}
export const selectValueFindByKey = (
  arrays,
  keyField,
  keyValue,
  selectField
) => {
  const data = findByKey(arrays, keyField, keyValue)
  if (data === undefined) return undefined
  return data[selectField]
}

export const content_types = [
  "Όλα",
  "Δελτία Τύπου",
  "Δημοσιεύσεις",
  "Βίντεο",
  "Podcast",
  "Νέα Ανακοινώσεις",
  "Γεγονότα",
]

export const getCategoryByName = name => {
  let item = category_menus.filter((item, i) => item.subtitle === name)
  if (item.length > 0) return item[0]
  return category_menus[0]
}
export const getCategoryIcon = name => {
  let item = category_menus.filter((item, i) => item.subtitle === name)
  if (item.length > 0) return item[0].icon
  return category_menus[0].icon
}
export const getDashboardCategoryIcon = name => {
  let item = dashboard_category_menus.filter(
    (item, i) => item.subtitle === name
  )
  if (item.length > 0) return item[0].icon
  return category_menus[0].icon
}
export const getCategoryId = name => {
  let item = category_menus
    .map((item, i) => (item.subtitle === name ? i : 0))
    .filter(ii => ii !== 0)
  if (item.length > 0) return item[0]
  return 0
}

export const getCategoryColorbyID = (categoryData, id) => {
  return categoryData?.filter(item => item.prismicId === id)[0]?.data.color
}

export const getCategoryOverlayImgbyID = (categoryData, id) => {
  return categoryData?.filter(item => item.prismicId === id)[0]?.data
    .overlay_img.url
}

export const dashboard_category_menus = [
  {
    icon: dash_Institutional,
    subtitle: "Διεθνείς σχέσεις",
  },
  {
    icon: dash_Economy,
    subtitle: "Θεσμική παρουσία",
  },
  {
    icon: dash_Home,
    subtitle: "Εργασία",
  },
  {
    icon: dash_Institutional,
    subtitle: "Θεσμική παρουσία",
  },
  {
    icon: dash_Contact,
    subtitle: "Κοινωνική πολιτική",
  },
]
export const category_menus = [
  {
    icon: Institutional,
    subtitle: "Θεσμική παρουσία",
    color: "#FF8275",
    items: [
      {
        name: "Διεθνείς σχέσεις",
        to: "/",
      },
      {
        name: "Κοινωνικός διάλογος",
        to: "/",
      },
      {
        name: "Συνεργασίες - δίκτυα",
        to: "/",
      },
      {
        name: "Συνδικαλιστική δράση",
        to: "/",
      },
      {
        name: "Κοινωνική δράση",
        to: "/",
      },
    ],
  },
  {
    icon: Economy,
    subtitle: "Οικονομία - Επιχειρηματικότητα",
    color: "#63C6DC",
    items: [
      {
        name: "Φορολογικό",
        to: "/",
      },
      {
        name: "Χρηματοδότηση",
        to: "/",
      },
      {
        name: "Αδειοδότηση",
        to: "/",
      },
      {
        name: "Ψηφιακή αλλαγή",
        to: "/",
      },
      {
        name: "Πράσινη μετάβαση",
        to: "/",
      },
      {
        name: "Κλάδοι & επαγγέλματα",
        to: "/",
      },
      {
        name: "Ειδικά θέματα ΜΜΕ",
        to: "/",
      },
    ],
  },
  {
    icon: Work,
    subtitle: "Εργασία",
    color: "#AF8373",
    items: [
      {
        name: "Ασφαλιστικό",
        to: "/",
      },
      {
        name: "Εργασιακές σχέσεις ",
        to: "/",
      },
      {
        name: "Ανεργία",
        to: "/",
      },
      {
        name: "Αυτοαπασχόληση",
        to: "/",
      },
      {
        name: "Ασφάλεια & υγιεινή",
        to: "/",
      },
    ],
  },
  {
    icon: Social,
    subtitle: "Κοινωνική πολιτική",
    color: "#63C6DC",
    items: [
      {
        name: "Ισότητα",
        to: "/",
      },
      {
        name: "Υγεία - πρόνοια",
        to: "/",
      },
      {
        name: "Ανισότητες",
        to: "/",
      },
      {
        name: "Μετανάστευση",
        to: "/",
      },
    ],
  },
  {
    icon: Training,
    subtitle: "Κατάρτιση & δεξιότητες",
    color: "#CFA867",
    items: [
      {
        name: "Εκπαίδευση και κατάρτιση ενήλικων",
        to: "/",
      },
      {
        name: "Εκπαιδευτικό σύστημα",
        to: "/",
      },
      {
        name: "Δεξιότητες",
        to: "/",
      },
      {
        name: "Εκπαίδευση στο χώρο εργασίας",
        to: "/",
      },
    ],
  },
]

export const resource_footer_menus = [
  {
    img: CardImg1,
    content: "Δελτία τύπου",
    date: "7 Δεκ. 2021",
    title: "Επίσκεψη Προέδρου ΓΣΕΒΕΕ στην Κύπρο",
    category: "Θεσμική παρουσία",
  },
  {
    img: CardImg2,
    content: "Δελτία τύπου",
    date: "7 Δεκ. 2021",
    title: "Διάγνωση αναγκών δεξιοτήτων: Μια ερευνητική προσέγγιση",
    category: "Θεσμική παρουσία",
  },
  {
    img: CardImg3,
    content: "Δελτία τύπου",
    date: "7 Δεκ. 2021",
    title: "Συνάντηση Προέδρου ΓΣΕΒΕΕ με τον νέο Πρόεδρο του ΠΑΣΟΚ-ΚΙΝΑΛ",
    category: "Κοινωνική πολιτική",
  },
]

export const filterCardContentBy = (card_content, filter) => {
  const filterCards = card_content.filter(item => {
    let flag = false
    filter.map(headerIndex => {
      if (parseInt(headerIndex) === item.card_id) {
        flag = true
      }
      return ""
    })
    return flag
  })
  return filterCards
}

export const getCardsInfo = (card, overlay) => {
  return card.map(itemCard => {
    const findOverlay = overlay.find(
      item => item.category_name === itemCard.content_category && item
    )
    return {
      content_type: itemCard.content_type,
      type: itemCard.content_type,
      title: itemCard.content_title,
      category: itemCard.content_category,
      img: itemCard.content_image.url,
      date: itemCard.content_date,
      icon: findOverlay.category_overlay.url,
      color: findOverlay.category_color,
      leftX: findOverlay.category_left_pos.split(", ")[0],
      leftY: findOverlay.category_left_pos.split(", ")[1],
      middleX: findOverlay.category_middle_pos.split(", ")[0],
      middleY: findOverlay.category_middle_pos.split(", ")[1],
      rightX: findOverlay.category_right_pos.split(", ")[0],
      rightY: findOverlay.category_right_pos.split(", ")[1],
      size: findOverlay.category_size,
    }
  })
}
