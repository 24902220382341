import React, { useState } from "react"
import { Link } from "gatsby"
import SearchField from "../search-field"
import { down } from "styled-breakpoints"
import logoEn from "../../images/logo-en.png"
import LogoEl from "../../images/svgs/logo-el.inline.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { AboutSubmenu, EnInformationSubmenu } from "./Submenus"

import styled from "styled-components"
import { min } from "lodash-es"

const NavLink = styled.a`
  display: block;

  &&&&& {
    padding: 0;
  }
`

const NavLinkWithSubmenu = styled.div`
  display: block;

  &&&&& {
    padding: 0;
  }
`

const Navbar = ({ type, location }) => {
  const [hambugerActive, setHambugerActiveState] = useState(false)
  const { i18n } = useTranslation()

  const hamburgerHandler = () => {
    setHambugerActiveState(!hambugerActive)
  }

  let humbugerClsName = "hamburger my-auto "
  let navMenuClsName = "navbar-nav "

  if (hambugerActive) {
    humbugerClsName += "active"
    navMenuClsName += "active"
  }

  let menuItems = [
    {
      kicker: "View",
      name: "Administrative Bodies",
      submenu: EnInformationSubmenu,
    },
    {
      kicker: "Discover",
      name: "Focus Areas",
      to: "/categories",
    },

    {
      kicker: "Learn About",
      name: "Members Network",
      to: "/members",
    },
    {
      kicker: "Come in",
      name: "Contact",
      to: "/contact",
    },
  ]

  if (i18n.resolvedLanguage === "el-gr") {
    menuItems = [
      {
        kicker: "Γνωρίστε τη",
        name: "ΓΣΕΒΕΕ",
        to: "/about",
        submenu: AboutSubmenu,
      },
      {
        kicker: "Ενημερωθείτε για τα",
        name: "ΠΕΔΙΑ ΔΡΑΣΗΣ",
        to: "/categories",
      },
      {
        kicker: "Περιηγηθείτε στα",
        name: "ΝΕΑ",
        to: "/resources",
      },
      {
        kicker: "Μάθετε για το",
        name: "ΔΙΚΤΥΟ ΜΕΛΩΝ",
        to: "/members",
      },
      {
        kicker: "Ελάτε σε",
        name: "ΕΠΙΚΟΙΝΩΝΙΑ",
        to: "/contact",
      },
    ]
  }

  return (
    <header className={type}>
      <nav className="navbar navbar-expand-lg">
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 1440px;
            margin: 0 auto;
            padding: 0 32px;

            ${down("md")} {
              padding: 0;
            }

            .logo-container {
              flex: 0 0 145px;

              @media screen and (min-width: 1025px) {
                flex: 0 0 250px;
              }

              a {
                display: block;
              }

              svg,
              img {
                width: 100%;
                height: auto;
              }
            }
          `}
        >
          <div className="logo-container">
            <Link to="/">
              {i18n.resolvedLanguage === "el-gr" && (
                <LogoEl width="160" height="60" />
              )}
              {i18n.resolvedLanguage === "en-us" && (
                <img
                  src={logoEn}
                  alt="gsevee logo"
                  width="160px"
                  height="60px"
                />
              )}
            </Link>
          </div>
          <div
            css={`
              display: flex;
              align-items: center;

              ${down("xl")} {
                flex-direction: column-reverse;
                align-items: unset;
              }
            `}
          >
            <div
              className={humbugerClsName}
              onClick={hamburgerHandler}
              onKeyDown={hamburgerHandler}
              role="button"
              tabIndex="0"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul
              className={navMenuClsName}
              css={`
                &&& {
                  ${down("xl")} {
                    border: none;
                  }
                }
              `}
            >
              {menuItems.map((item, idx) => (
                <li
                  css={`
                    padding-right: 28px;

                    ${down("xl")} {
                      padding-right: 0;

                      &:not(:last-child) {
                        padding-right: 28px;
                      }
                    }
                  `}
                  className="nav-item"
                  key={idx}
                >
                  {item.submenu ? (
                    <NavLinkWithSubmenu
                      className="nav-link"
                      css={`
                        cursor: pointer;
                        position: relative;

                        &:hover,
                        &:focus,
                        &:focus-within {
                          .submenu {
                            display: block;
                          }
                        }
                      `}
                    >
                      <p className="sub-name">{item.kicker}</p>
                      <p className="link-name">{item.name}</p>
                      <div
                        css={`
                          display: none;
                          width: 100%;
                          margin-top: 1rem;

                          @media screen and (min-width: 1025px) {
                            position: absolute;
                            margin-top: 0;
                            width: 211px;
                            left: -16px;
                            padding-top: 12px;
                            z-index: 999;
                          }
                        `}
                        className="submenu"
                      >
                        <div
                          css={`
                            padding: 24px;
                            background: #002f6c;
                            box-shadow: 0px 14px 24px rgba(0, 47, 108, 0.07);
                            border-radius: 6px;
                          `}
                        >
                          <item.submenu />
                        </div>
                      </div>
                    </NavLinkWithSubmenu>
                  ) : (
                    <NavLink
                      as={Link}
                      className="nav-link"
                      activeClassName="active"
                      to={item.to}
                    >
                      <p className="sub-name">{item.kicker}</p>
                      <p className="link-name">{item.name}</p>
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
            {type !== "third" && <SearchField location={location} />}
          </div>
        </div>
      </nav>
    </header>
  )
}
export default Navbar
