import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Index } from "lunr"
import { down } from "styled-breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { toGreeklish } from "greek-utils"
import { navigate } from "gatsby"

const SearchField = ({ location }) => {
  const [value, setValue] = useState("")
  const [results, setResults] = useState([])
  const { t } = useTranslation()

  const { LunrIndex } = useStaticQuery(graphql`
    query {
      LunrIndex
    }
  `)
  const index = Index.load(LunrIndex.index)
  const { store } = LunrIndex
  const handleChange = e => {
    const query = e.target.value
    setValue(query)
    try {
      const search = index
        .search(`+*${query.toLowerCase()}*`)
        .map(({ ref }) => {
          return {
            slug: ref,
            ...store[ref],
          }
        })
      setResults(search)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      css={`
        position: relative;

        &&& {
          ${down("xl")} {
            margin: 0;
            margin-bottom: 16px;
            input {
              width: 100%;
            }
          }
        }
      `}
      className="search-field"
    >
      <input
        css={`
          &&& {
            border-radius: ${value ? "6px 6px 0 0" : "6px"};
          }
        `}
        className="search-input"
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={t("common:search")}
        onKeyDown={e => e.key === "Enter" && navigate(`/resources?q=${value}`)}
      />
      {value && (
        <div
          css={`
            position: absolute;
            width: 100%;
            border: 1px solid #002f6c;
            padding: 0 16px;
            background: #fff;
            color: #001838;
            z-index: 100;
            max-height: 300px;
            overflow: auto;
            border-radius: 0 0 6px 6px;
          `}
        >
          {results.length ? (
            <ul>
              {results.map(result => (
                <li
                  css={`
                    padding: 12px 0;

                    &:not(:last-child) {
                      border-bottom: 1px solid rgba(0, 47, 108, 0.2);
                    }
                  `}
                  key={result.slug}
                >
                  <Link to={`/resource/${toGreeklish(result.slug)}`}>
                    <h4
                      css={`
                        font-size: 12px;
                        line-height: 14px;
                        font-weight: 400;
                        margin: 0;
                        color: #002f6c;
                      `}
                    >
                      {result.title}
                    </h4>
                    <h5
                      css={`
                        color: rgba(0, 47, 108, 0.46);
                        font-size: 12px;
                        line-height: 14px;
                        margin: 0;
                        margin-top: 2px;
                      `}
                    >
                      {result.category}
                    </h5>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p>Sorry, no matches found.</p>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchField
