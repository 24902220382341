import React from "react"
import styled from "styled-components"
import { returnLeftPosition, returnTopPosition } from "../../utils/func"

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 4px 4px 0px 0px;
  height: 180px;

  .card-image {
    position: absolute;
    z-index: 1;
    left: ${props => returnLeftPosition(props.title, props.position)};
    top: ${props => returnTopPosition(props.title, props.position)};

    .w321 {
      display: inline-block;
    }
  }
`
const ImageWrapper = props => {
  const { position, categoryData, color, type, icon, children, ...rest } = props

  // prettier-ignore
  const categoryTitle = categoryData?.find(item => item.prismicId === type)?.data.title

  return (
    <Wrapper
      className="card-image-wrapper"
      color={color}
      title={categoryTitle}
      position={position}
      {...rest}
    >
      {icon && (
        <div className="card-image grayed">
          <img className="w321" src={icon} alt="" />
        </div>
      )}
      <div
        css={`
          height: 100%;

          .gatsby-image-wrapper {
            height: 100%;
            filter: grayscale(1);
            object-fit: cover;
          }
        `}
        className="children"
      >
        {children}
      </div>
    </Wrapper>
  )
}
export default ImageWrapper
