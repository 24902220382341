import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import Footer3 from "./footer3/Footer3"
import FooterCard from "../common/FooterCard"
import { resource_footer_menus, category_menus } from "../../utils/staticData"
import { Typography } from "../styled/typography"
import { Holy } from "../../utils/imgImport"
import footerIconEl from "../../images/footer-icon-el.jpeg"
import footerIconEn from "../../images/footer-icon-en.jpg"
import { useTranslation } from "gatsby-plugin-react-i18next"
import facebook from "../../images/icons/facebook.svg"
import twitter from "../../images/icons/twitter.svg"
import youtube from "../../images/icons/youtube.svg"
import { toGreeklish } from "greek-utils"
import Slider from "react-slick"

const CategoryCol = ({ data, category }) => {
  return (
    <div className="mx-2">
      <img className="mb-3" src={data.icon} alt="category icon" />
      <Typography
        css={`
          height: 48px;
          width: 150px;
          display: flex;
          align-items: flex-end;
        `}
        className="mb-3"
        variant="h6_regular"
      >
        {data.subtitle}
      </Typography>
      <ul>
        {data.items.map((item, idx) => (
          <li key={idx}>
            <Link
              to={`/category/${toGreeklish(category.uid)}?subtag=${item.name}`}
            >
              <Typography className="mb-3" variant="text">
                {item.name}
              </Typography>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const ICONS = {
  facebook,
  twitter,
  youtube,
}

const FooterCol = ({ data }) => {
  return (
    <div className="my-3">
      <Typography
        className="mt-3 mt-md-0 mb-3"
        variant="subtitle_medium"
        color="white"
      >
        {data.subtitle}
      </Typography>
      <ul>
        {data.items.map((item, idx) => (
          <li
            key={idx}
            css={`
              padding: 0.5rem 0;
            `}
          >
            <a href={item.to} className="d-flex align-items-center">
              {item.icon && (
                <img className="me-3" src={ICONS[item.icon]} alt={item.name} />
              )}
              <Typography variant="text" color="white" as="span">
                {item.name}
              </Typography>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

const ResourceFooterWrapper = styled.div`
  & h1 {
    /* margin-top: 84px; */
    margin-bottom: 40px;
  }
  & .card-wrapper {
    display: flex;
    width: auto;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    // className="d-flex flex-wrap justify-content-between"
  }
  & .wrapper {
    overflow-x: auto;
  }
`
const Footer = props => {
  const { t, i18n, ready } = useTranslation(undefined, { useSuspense: false })
  const data = useStaticQuery(graphql`
    query {
      allPrismicCategory(
        sort: { fields: data___order, order: ASC }
        filter: { lang: { eq: "el-gr" } }
      ) {
        nodes {
          data {
            title
            color
            icon {
              url
              gatsbyImageData
            }
          }
          uid
        }
      }
    }
  `)

  const categories = data.allPrismicCategory.nodes

  const { location } = props
  const isResourcePage =
    location?.pathname === "/resource" || location?.pathname === "/resource/"
  const isMemberPage =
    location?.pathname === "/members" || location?.pathname === "/members/"

  const footerMenus = ready ? t("common:footerMenus") : []
  const footerImage =
    i18n.resolvedLanguage === "en-us" ? footerIconEn : footerIconEl

  const settings = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <footer>
      {location?.pathname !== "/about" && i18n.resolvedLanguage === "el-gr" && (
        <div className="category-menu border-top">
          <div className="container">
            {isResourcePage ? (
              <ResourceFooterWrapper>
                <h1>Σχετικό περιεχόμενο</h1>
                <div className="wrapper">
                  <div className="card-wrapper">
                    {resource_footer_menus.map((card, i) => (
                      <FooterCard {...card} key={i} index={i}>
                        {card.title}
                      </FooterCard>
                    ))}
                  </div>
                </div>
              </ResourceFooterWrapper>
            ) : isMemberPage ? (
              <Footer3 />
            ) : (
              <Slider {...settings}>
                {category_menus.map((item, idx) => (
                  <CategoryCol
                    category={categories.find(
                      x => x.data.title === item.subtitle
                    )}
                    data={item}
                    key={idx}
                  />
                ))}
              </Slider>
            )}
          </div>
        </div>
      )}
      <div className="footer-menu">
        <div
          className="container"
          css={`
            position: relative;
          `}
        >
          <div
            css={`
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            `}
          >
            {footerMenus.map((item, idx) => (
              <FooterCol data={item} key={idx} />
            ))}
          </div>
          <div
            css={`
              border-top: 1px solid rgba(255, 255, 255, 0.2);
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);
              padding: 16px 0;
              display: flex;
              align-items: center;

              ${down("lg")} {
                flex-direction: column;
              }
            `}
          >
            <img
              css={`
                width: 100%;
                max-width: 475px;
                height: auto;
              `}
              src={footerImage}
              alt="footer icon"
            />
            <p
              css={`
                color: #fff;
                margin-left: 16px;
                font-size: 16px;
                line-height: 19px;

                ${down("lg")} {
                  margin: 0;
                  margin-top: 16px;
                }
              `}
            >
              {t("common:footerText")}
            </p>
          </div>
          <div
            className="text-start text-md-end"
            css={`
              margin-top: 12px;
            `}
          >
            <img src={Holy} alt="holy" />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
