import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Navbar from "../components/ui/navbar"
import Section from "../components/common/Section"
import CategoryHero from "../components/Category/CategoryHero"
import CategoryContent from "../components/Category/CategoryContent"
import FilterPanel from "../components/Filters/FilterPanel"
import { ContentBox4 } from "../components/common/ContentBox"
import { useQueryParam, ArrayParam, withDefault } from "use-query-params"
import { useTranslation } from "gatsby-plugin-react-i18next"

const H3 = styled.h3`
  font-family: CeraGR-Bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: "26px 0";
  color: ${props => props.theme.colors.blue};
`

const Category = ({ location, data }) => {
  const resourcesData = data.allPrismicResource.nodes
  const categoryItemData = data.prismicCategory
  const subtagsData = data.allPrismicSubtag.nodes
  const categoryData = data.allPrismicCategory.nodes
  const { i18n } = useTranslation()

  const filteredResources = resourcesData.filter(item =>
    [item.data.category.id, ...item.data.categories.map(x => x.id)].includes(
      categoryItemData.prismicId
    )
  )

  const [category_filter, setCategoryFilter] = useState([])
  const [subtag_filter, setSubtagFilter] = useQueryParam(
    "subtag",
    withDefault(ArrayParam, [])
  )
  const [content_filter, setContentFilter] = useQueryParam(
    "content_type",
    withDefault(ArrayParam, [])
  )
  const [filteredItems, setFilteredItems] = useState(filteredResources)

  // const subtag_filters = [{ label: "Όλα", value: "Όλα" }]

  const getSubtagName = tagId =>
    subtagsData.filter(item => item.prismicId === tagId)[0]?.data.tag_name

  useEffect(() => {
    let newfilteredItems = filteredResources.filter(item => {
      return (
        ((subtag_filter.length === 0 ||
          subtag_filter.includes(
            subtag_filter.filter(subtag =>
              item.data.group_of_subtags.find(
                resource => getSubtagName(resource.subtag.id) === subtag
              )
            )[0]
          )) &&
          content_filter.length === 0) ||
        content_filter.includes(item.data.resource_type)
      )
    })
    setFilteredItems(newfilteredItems)
  }, [subtag_filter, content_filter, resourcesData, categoryData])

  return (
    <Layout location={location} type="primary">
      <Seo title="Category Page"></Seo>

      <CategoryHero
        categoryItemData={categoryItemData}
        categoryData={categoryData}
        subtagsData={subtagsData}
        resourceData={resourcesData}
      />

      <CategoryContent data={categoryItemData.data} />

      {i18n.resolvedLanguage === "el-gr" && (
        <Section id="activity-section" className="activity-section">
          <div style={{ backgroundColor: "white", margin: "40px 0" }}>
            <div className="container">
              <H3>Διαβάστε σε αυτή την κατηγορία:</H3>
            </div>
            <div className="container ">
              <FilterPanel
                category_filter={category_filter}
                content_filter={content_filter}
                subtag_filter={subtag_filter}
                setCategoryFilter={setCategoryFilter}
                setContentFilter={setContentFilter}
                setSubtagFilter={setSubtagFilter}
                filteredItems={filteredItems}
                subtag_filters={[
                  { label: "Όλα", value: "Όλα" },
                  ...subtagsData.map(subtag => ({
                    label: subtag.data.tag_name,
                    value: subtag.prismicId,
                  })),
                ]}
                categoryPage={true}
              />

              <div className="row mt-5">
                {filteredItems.map((item, idx) => (
                  <div
                    className="col-sm-6 col-lg-4 d-flex justify-content-center"
                    key={idx}
                  >
                    <ContentBox4
                      resourceData={item}
                      categoryData={categoryData}
                      categoryItemData={categoryItemData.data}
                      dashboard={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Section>
      )}
    </Layout>
  )
}
export default Category

export const query = graphql`
  query CategoryQuery($id: String, $uid: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicCategory(id: { eq: $id }) {
      data {
        title
        description
        icon {
          gatsbyImageData
          url
        }
        color
        overlay_img {
          url
        }
        key_questioning_title
        key_questioning_description
        intervation_title
        intervation_description
        points {
          point {
            html
          }
        }
      }
      uid
      prismicId
    }
    allPrismicResource(
      limit: 100
      sort: { fields: data___post_date, order: DESC }
    ) {
      nodes {
        data {
          category {
            id
            uid
          }
          categories {
            item {
              id
            }
          }
          title
          resource_type
          resource_photo {
            gatsbyImageData
            url
          }
          post_date
          overlay_position
          group_of_subtags {
            subtag {
              id
              uid
            }
          }
        }
        uid
      }
    }
    allPrismicSubtag(
      filter: {
        lang: { eq: "el-gr" }
        data: { category: { uid: { eq: $uid } } }
      }
    ) {
      nodes {
        data {
          tag_name
          category {
            id
          }
        }
        prismicId
      }
    }
    allPrismicCategory(filter: { lang: { eq: "el-gr" } }) {
      nodes {
        prismicId
        data {
          title
          color
          icon {
            url
          }
          overlay_img {
            url
          }
        }
      }
    }
  }
`
